import category1 from "../assets/img/rki/category/pr1.png";
import category2 from "../assets/img/rki/category/pr2.png";
import category3 from "../assets/img/rki/category/pr3.png";
import category4 from "../assets/img/rki/category/pr4.png";
import category5 from "../assets/img/rki/category/pr5.png";
import category6 from "../assets/img/rki/category/pr6.png";
import category7 from "../assets/img/rki/category/pr1.png";
import category8 from "../assets/img/rki/category/pr2.png";
export const featuredDB = [
  {
    _id: 1,
    name: "Apple Cinema 30-inch HD Flat-Panel Display",
    price: "1,450.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category1,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 2,
    name: "Apple® IPod Touch (6th Generation)",
    price: "1,150.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category2,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 3,
    name: "HP LP3065 LCD Monitor 30 - 2560 x 1600 @ 60 Hz",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category3,    
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 4,
    name: "SAMSUNG Galaxy Tab E 9.6 16GB Android 6.0",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category4,    
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 5,
    name: "Apple iPod shuffle 2 GB Black (3rd Generation)",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category5,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 6,
    name: "Canon EOS 5D 12.8 MP Digital SLR Camera",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category6,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 7,
    name: "HTC Touch HD Windows Mobile smartphone",
    price: "1,450.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category7,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 8,
    name: "Samsung SyncMaster 2443BW - LCD monitor - 24 Series",
    price: "1,150.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category8,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  }
];
