import React from "react";
import Rating from "../../../shared/rating/Rating";

const Testimonials = () => {
  return (
    <>
      <section className="testimonialSec sectionPD">
        <div className="container">
          <div className="testimonialInfo">
            <div className="fisherman-content mb-4">
              <h3>Testimonials</h3>
              <p>What they say</p>
            </div>
            <div
              className="testimonialCard"
              id="myTab"
              role="tablist"
            >
              <div className="userImage">
                <ul>
                  <li className="opacity">
                    <button
                      className="nav-link"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      <img
                        src="http://corano.demo.towerthemes.com/image/cache/catalog/testimonials/testimonial2-100x100.png"
                        alt="Testimonial"
                        className="img-fluid"
                      />
                    </button>
                  </li>
                  <li>
                    <button
                      className="nav-link active"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      <img
                        src="http://corano.demo.towerthemes.com/image/cache/catalog/testimonials/testimonial3-100x100.png"
                        alt="Testimonial"
                        className="img-fluid"
                      />
                    </button>
                  </li>
                  <li className="opacity">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    >
                      <img
                        src="http://corano.demo.towerthemes.com/image/cache/catalog/testimonials/testimonial1-100x100.png"
                        alt="Testimonial"
                        className="img-fluid"
                      />
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabindex="0"
                >
                  <div className="testimonial-box">
                    <p>
                      Vivamus a lobortis ipsum, vel condimentum magna. Etiam id
                      turpis tortor. Nunc scelerisque, nisi a blandit varius,
                      nunc purus venenatis ligula, sed venenatis orci augue nec
                      sapien. Cum sociis natoque p..
                    </p>
                    <Rating />
                    <span className="testimonial-author">
                      Lindsy Neloms
                    </span>{" "}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabindex="0"
                >
                  <div className="testimonial-box">
                    <p>
                      Vivamus a lobortis ipsum, vel condimentum magna. Etiam id
                      turpis tortor. Nunc scelerisque, nisi a blandit varius,
                      nunc purus venenatis ligula, sed venenatis orci augue nec
                      sapien. Cum sociis natoque p..
                    </p>
                    <Rating />
                    <span className="testimonial-author">
                      Rebecka Filson
                    </span>{" "}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="contact-tab-pane"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                  tabindex="0"
                >
                  <div className="testimonial-box">
                    <p>
                      Vivamus a lobortis ipsum, vel condimentum magna. Etiam id
                      turpis tortor. Nunc scelerisque, nisi a blandit varius,
                      nunc purus venenatis ligula, sed venenatis orci augue nec
                      sapien. Cum sociis natoque p..
                    </p>
                    <Rating />
                    <span className="testimonial-author">
                    Amber Laha
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
